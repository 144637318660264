"use strict";
/**
 *  Shared logic for the cupboards.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    data() {
        return {
            // Fixed values, as defined in the API
            cupboardTypesOf: [
                'fume',
                'chemical',
            ],
            cupboardStatuses: [
                'unknown',
                'good',
                'moderate',
                'bad',
            ],
            // Coldtec green, orange and bad hexes
            cupboardStatusColors: {
                good: '#18E206',
                moderate: '#E26906',
                bad: '#E20613',
            },
        };
    },
    computed: {
        // Get the right reference, as this logic is used in both the details for
        // the dirty cupboard as in the tile for the original cupboard data - where
        // the data has been wrapped in a cupboard row object
        cupboardRef() {
            return this.dirty_cupboard || this.cupboardRow.data;
        },
        // Returns the translation of the type of cupboard
        cupboardType() {
            return this.translateCupboardTypeOf(this.cupboardRef.type_of, true);
        },
        // Returns the latest assessment if present, which is the first in the Array
        // because they are sorted descending based on their added date
        cupboardLatestAssessment() {
            if (!this.cupboardRef.assessments.length) {
                return null;
            }
            return this.cupboardRef.assessments[0];
        },
        // Returns the translated current status of the cupboard, which is
        // equal to the general status of the latest assessment
        cupboardStatus() {
            if (!this.cupboardLatestAssessment) {
                return this.translateCupboardStatus('unkown');
            }
            return this.translateCupboardStatus(this.cupboardLatestAssessment.status.general);
        },
        // Returns the HEX color that belongs to the current general status
        // of the cupboard, or undefined if it is unkown
        cupboardStatusColor() {
            if (!this.cupboardLatestAssessment) {
                return undefined;
            }
            return this.cupboardStatusColors[this.cupboardLatestAssessment.status.general];
        },
    },
    methods: {
        translateCupboardTypeOf(typeOf, capitalised = false) {
            let translatedTypeOf = 'onbekend';
            switch (typeOf) {
                case 'fume':
                    translatedTypeOf = 'zuurkast';
                    break;
                case 'chemical':
                    translatedTypeOf = 'chemicaliën kast';
                    break;
            }
            if (capitalised) {
                // @ts-ignore
                translatedTypeOf = translatedTypeOf.charAt(0).toUpperCase() + translatedTypeOf.substring(1);
            }
            return translatedTypeOf;
        },
        translateCupboardStatus(status) {
            let translatedStatus = 'Onbekend';
            switch (status) {
                case 'good':
                    translatedStatus = 'Goed';
                    break;
                case 'moderate':
                    translatedStatus = 'Matig';
                    break;
                case 'bad':
                    translatedStatus = 'Slecht';
                    break;
            }
            return translatedStatus;
        },
        getCupboardStatusColor(status) {
            return this.cupboardStatusColors[status];
        },
    },
};
