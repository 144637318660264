var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        ("order__urgency order__urgency--" + _vm.urgency), 
        {
            'order__urgency--clickable': _vm.clickable,
        } ],style:({
        width: (_vm.size + "px"),
        height: (_vm.size + "px"),
        'background-size': (_vm.size + "px " + _vm.size + "px"),
    }),attrs:{"title":_vm.title},on:{"click":_vm.toggle}})}
var staticRenderFns = []

export { render, staticRenderFns }