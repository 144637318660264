"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getters = void 0;
exports.getters = {
    /**
     *  Used to get the details of the user.
     */
    user: (state) => {
        return state.user;
    },
    /**
     *  Used to get the currently active region.
     */
    region: (state) => {
        if (!state.user) {
            return null;
        }
        return state.user.active_region;
    },
    /**
     *  Used to get the full name of the user.
     */
    userName: (state) => {
        if (!state.user) {
            return '';
        }
        return !state.user.name.last ? state.user.name.first : `${state.user.name.first[0]}. ${state.user.name.last}`;
    },
    // Loading state includes the loading of the app, module and filter
    loading: (state) => {
        return state.loading;
    },
    notification: (state) => {
        return state.notification;
    },
    // Filter, including the states for the orders and cupboards
    filter: (state) => {
        return state.filter;
    },
    /**
     *  Used to get general data.
     */
    buildings: (state) => {
        return state.data.buildings;
    },
    mechanics: (state) => {
        return state.data.mechanics;
    },
    users: (state) => {
        return state.data.users;
    },
};
