"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrdersStore = exports.state = void 0;
const orders_actions_1 = require("./orders.actions");
const orders_getters_1 = require("./orders.getters");
const orders_mutations_1 = require("./orders.mutations");
exports.state = {
    labels: [],
};
exports.OrdersStore = {
    namespaced: true,
    state: exports.state,
    mutations: orders_mutations_1.mutations,
    actions: orders_actions_1.actions,
    getters: orders_getters_1.getters,
};
