"use strict";
/**
 *  Mixin for creating globally applicable functions to check if a user has a
 *  specific right or role for doing something.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    methods: {
        /**
         *  Used to check if the current logged in user has a specific role.
         */
        userHasRole(role) {
            let hasRole = false;
            if (!this.$store.getters.user) {
                return hasRole;
            }
            this.$store.getters.user.roles.map((userRole) => {
                if (userRole.label === role) {
                    hasRole = true;
                }
            });
            return hasRole;
        },
        /**
         *  Used to check if the current logged in user has a specific right.
         */
        userCan(right) {
            let can = false;
            if (!this.$store.getters.user) {
                return can;
            }
            this.$store.getters.user.roles.map((role) => {
                if (role.rights.indexOf(right) > -1) {
                    can = true;
                }
            });
            return can;
        },
    },
};
