"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CupboardsStore = exports.state = void 0;
const cupboards_actions_1 = require("./cupboards.actions");
const cupboards_getters_1 = require("./cupboards.getters");
const cupboards_mutations_1 = require("./cupboards.mutations");
exports.state = {
    measuringEquipment: [],
};
exports.CupboardsStore = {
    namespaced: true,
    state: exports.state,
    mutations: cupboards_mutations_1.mutations,
    actions: cupboards_actions_1.actions,
    getters: cupboards_getters_1.getters,
};
