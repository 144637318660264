var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"cupboard-tile tile",class:{
        'tile--hide': _vm.hide,
    }},[_c('ul',{staticClass:"cupboard-tile__details"},[_c('li',{staticClass:"cupboard-tile__detail cupboard-tile__detail--type_of",on:{"click":function($event){return _vm.$emit('open')}}},[_c('strong',{staticClass:"detail__label"},[_vm._v("Soort")]),_vm._v(" "+_vm._s(_vm.cupboardType)+" ")]),_c('li',{staticClass:"cupboard-tile__detail cupboard-tile__detail--type",on:{"click":function($event){return _vm.$emit('open')}}},[_c('strong',{staticClass:"detail__label"},[_vm._v("Type")]),_vm._v(" "+_vm._s(_vm.cupboardRow.data.type)+" ")]),_c('li',{staticClass:"cupboard-tile__detail cupboard-tile__detail--number",on:{"click":function($event){return _vm.$emit('open')}}},[_c('strong',{staticClass:"detail__label"},[_vm._v("QR-Nummer")]),_c('span',{staticClass:"tile__filterable",domProps:{"innerHTML":_vm._s(_vm.cupboardRow.data.number)}})]),_c('li',{staticClass:"cupboard-tile__detail cupboard-tile__detail--building",on:{"click":function($event){return _vm.$emit('open')}}},[_c('div',{staticClass:"building__left"}),_c('div',{staticClass:"building__right"},[_c('strong',{staticClass:"detail__label"},[_vm._v("Gebouw")]),_c('b',[_vm._v(_vm._s(_vm.cupboardRow.data.building.number))]),_vm._v(" - "+_vm._s(_vm.cupboardRow.data.building.name)+" ")])]),_c('li',{staticClass:"cupboard-tile__detail cupboard-tile__detail--location",on:{"click":function($event){return _vm.$emit('open')}}},[_c('strong',{staticClass:"detail__label"},[_vm._v("Locatie")]),_vm._v(" "+_vm._s(_vm.cupboardRow.data.location)+" ")]),_c('li',{staticClass:"cupboard-tile__detail cupboard-tile__detail--status",on:{"click":function($event){return _vm.$emit('open')}}},[_c('strong',{staticClass:"detail__label"},[_vm._v("Status")]),_c('div',{staticClass:"cupboard-tile__status"},[_c('span',{staticClass:"cupboard__status-indicator",style:({
                        'background-color': _vm.cupboardStatusColor,
                    })}),_c('span',{staticClass:"cupboard__status-label"},[_vm._v(_vm._s(_vm.cupboardStatus))])])]),_c('li',{staticClass:"cupboard-tile__detail cupboard-tile__detail--select",class:{
                'select--disabled': !_vm.cupboardRow.data.assessments.length,
            },attrs:{"title":_vm.selectTitle},on:{"click":function($event){return _vm.$emit('toggle-select')}}},[_c('div',{staticClass:"select__checkbox",class:{
                    'select__checkbox--selected': _vm.cupboardRow.selected,
                }},[_c('i',{staticClass:"checkbox__icon"})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }