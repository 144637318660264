var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'input',
    ("input--" + _vm.type),
    {
        'input--icon': _vm.icon,
        'input--error': _vm.error,
    }
]},[_c('confirm-modal',{ref:"confirmModal"}),(_vm.label)?_c('label',{attrs:{"for":_vm.name}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('i',{staticClass:"input__icon"}),_c('div',{staticClass:"input__data",class:{
            'input__data--disabled': _vm.disabled,
        }},[(_vm.type === 'select' && _vm.options)?_c('div',{staticClass:"input__select",class:{
                'input__select--closed': !_vm.select.open,
            },on:{"click":_vm.toggleSelect}},[_c('i',{staticClass:"select__arrow",class:{
                    'select__arrow--flipped': _vm.select.open
                }}),_c('div',{ref:"selected",staticClass:"select__selected"},[(_vm.value)?_vm._t("default",null,{"option":_vm.value}):_c('span',[_vm._v(_vm._s(_vm.placeholder))])],2),_c('div',{ref:"options",staticClass:"select__options"},[_vm._l((_vm.options),function(option){return _c('div',{key:option.id || option,staticClass:"select__option",class:{
                        'select__option--selected': _vm.value
                            ? _vm.value.id
                                ? _vm.value.id === option.id
                                : _vm.value === option
                            : false,
                    },on:{"click":function($event){return _vm.update(option)}}},[_vm._t("default",null,{"option":option})],2)}),(_vm.deselectable)?_c('div',{staticClass:"select__option",on:{"click":function($event){return _vm.$emit('input', null)}}},[_vm._t("deselect")],2):_vm._e()],2)]):((_vm.type === 'textarea'))?_c('div',{staticClass:"input__textarea"},[_c('textarea',{ref:"input",class:{
                    'input--empty': !_vm.value || !_vm.value.length,
                },attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"maxlength":_vm.maxlength},domProps:{"value":_vm.value},on:{"change":function($event){return _vm.update($event.target.value)}}})]):((_vm.type === 'files'))?_c('div',{staticClass:"input__files"},[_c('ul',{staticClass:"list list--files"},[_vm._l((_vm.files),function(file,index){return _c('li',{key:file.id,staticClass:"list-item file",class:{
                        'file--disabled': _vm.localFileData[index] && _vm.localFileData[index].upload.ing,
                    }},[_c('span',{staticClass:"list-item__icon"}),_c('span',{ref:("file-name-wrapper-" + (file.id)),refInFor:true,staticClass:"list-item__name",class:{
                            'list-item__name--adapted': _vm.localFileData[index] && _vm.localFileData[index].adapted_name.show,
                        },attrs:{"title":"Klik om het bestand te downloaden"},on:{"click":function($event){return _vm.$emit('download', file)}}},[_c('span',{staticClass:"name name--adapted"},[_vm._v(" "+_vm._s(_vm.localFileData[index] && _vm.localFileData[index].adapted_name ? _vm.localFileData[index].adapted_name.name : '')+" ")]),_c('span',{ref:("file-name-text-" + (file.id)),refInFor:true,staticClass:"name name--original"},[_vm._v(_vm._s(file.name))])]),(!_vm.disabled)?_c('div',{staticClass:"list-item__options"},[((_vm.localFileData[index] && !_vm.localFileData[index].upload.ing))?_c('div',{staticClass:"list-item__actions"},[_c('i',{staticClass:"list-item__delete",on:{"click":function($event){return _vm.deleteFile(file)}}})]):_c('div',{staticClass:"file__uploader"},[_c('span',{staticClass:"file__uploaded",style:({ 'width': (((_vm.localFileData[index] ? _vm.localFileData[index].upload.percent : 0)) + "%") })})])]):_vm._e()])}),_c('li',{staticClass:"files__input"},[_c('input',{ref:"fileSelect",attrs:{"type":"file","multiple":"true","accept":"image/*, .pdf, .doc, .docx, .odt, .xls, .xlsx, .ods"},on:{"change":function($event){return _vm.addFiles()}}})]),_c('li',{staticClass:"files__add",class:{ 'files__add--extended': !_vm.files.length },on:{"click":_vm.selectFile}},[_vm._m(0)])],2)]):_c('input',{ref:"input",class:{
                'input--empty': !(_vm.value && _vm.value.length)
            },attrs:{"type":_vm.type,"autocomplete":_vm.autocomplete,"name":_vm.name,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"min":_vm.min,"max":_vm.max,"maxlength":_vm.maxlength,"step":_vm.step},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.update($event.target.value)}}}),(_vm.hint.show)?_c('span',{class:("input__hint input__hint--" + (_vm.hint.type))},[_vm._v(" "+_vm._s(_vm.hint.text)+" ")]):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add__wrapper"},[_c('i',{staticClass:"add__icon"}),_c('span',{staticClass:"add__text"},[_vm._v("Documenten toevoegen")])])}]

export { render, staticRenderFns }